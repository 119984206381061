<template>
  <div>
    <div
      class="h-470 hidden md:flex items-center"
      :class="
        $defaultLang === 'ar' && $route.name === 'newHome'
          ? 'bg-footer-ar'
          : 'bg-footer'
      "
      v-if="downLoadApp"
    >
      <div class="flex flex-col container">
        <h1 class="text-60 text-black font-EffraR mb-3">
          {{ $t("patient.app_download") }} !
        </h1>
        <span class="text-lg font-EffraR">
          {{ $t("patient.download_app_description") }}
        </span>

        <div class="text-lg font-EffraM pb-1 pt-55">
          {{ $t("patient.obtenir_un_lien") }}
        </div>

        <div
          class="flex flex-col xl:flex-row gap-x-3 mb-5 w-1/2 xl:w-500"
          id="downloadAppFooter"
        >
          <dok-input
            size="lg"
            d-placeholder="6 64 56 34 32"
            dir="ltr"
            :d-model.sync="inputPhoneNumber.value"
            :custom-class="inputPhoneNumber.error ? 'border-red-500' : ''"
          >
            <template v-slot:leftIcon>
              <span
                class="
                  border-0 border-r-2 border-solid
                  h-full
                  flex
                  items-center
                  justify-center
                  font-EffraR
                  text-gray-800 pr-1
                "
                :class="
                  inputPhoneNumber.error
                    ? 'border-red-500'
                    : 'border-borderInput'
                "
              >
                +212
              </span>
            </template>
          </dok-input>

          <dok-button
            custom-class="w-full"
            size="lg"
            bg="rose"
            @click.native="onSentSMS"
          >
            {{ $t("patient.sent_sms") }}
          </dok-button>
        </div>

        <ul>
          <li class="text-base font-EffraR py-1 flex items-center">
            <img
              src="/art/google-play-line_1.png"
              srcset="
                /art/google-play-line_1@2x.png 2x,
                /art/google-play-line_1@3x.png 3x
              "
              alt="android"
              class="mr-1"
            />
            {{ $t("patient.googlePlay.one") }}
          </li>
          <li class="text-base font-EffraR py-1 flex items-center">
            <img
              src="/art/apple-line_1.png"
              srcset="/art/apple-line_1@2x.png 2x, /art/apple-line_1@3x.png 3x"
              alt="ios"
              class="mr-1"
            />
            {{ $t("patient.appStore.one") }}
          </li>
        </ul>
      </div>
    </div>

    <div
      class="flex"
      :class="reverseFooter ? `flex-col xl:flex-col-reverse ${customClassLink}` : `flex-col ${customClassLink}`"
    >
      <div class="py-5 xl:py-0 px-5 xl:px-0">
        <div
          class="bg-grayfoot shadow xl:shadow-none rounded-cu xl:rounded-none"
        >
          <div class="container py-8 xl:py-3">
            <div
              class="
                flex flex-col
                xl:flex-row
                items-stretch
                xl:items-center
                justify-between
              "
            >
              <div class="flex-1 flex gap-x-4 mb-5 xl:mb-0">
                <a href="https://www.facebook.com/Dokmaroc01" target="_blank">
                  <img
                    src="/social_networks/facebook/index.png"
                    srcset="
                      /social_networks/facebook/index@2x.png 2x,
                      /social_networks/facebook/index@3x.png 3x
                    "
                    alt="dok facebook"
                  />
                </a>
                <a href="https://instagram.com/dokmaroc" target="_blank">
                  <img
                    src="/social_networks/instagram/index.png"
                    srcset="
                      /social_networks/instagram/index@2x.png 2x,
                      /social_networks/instagram/index@3x.png 3x
                    "
                    alt="dok instagram"
                  />
                </a>
                <a class="hidden" href="#">
                  <img
                    src="/social_networks/youtube/index.png"
                    srcset="
                      /social_networks/youtube/index@2x.png 2x,
                      /social_networks/youtube/index@3x.png 3x
                    "
                    alt="youtube"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/dokmarok/"
                  target="_blank"
                >
                  <img
                    src="/social_networks/linkedin/index.png"
                    srcset="
                      /social_networks/linkedin/index@2x.png 2x,
                      /social_networks/linkedin/index@3x.png 3x
                    "
                    alt="dok linkedin"
                  />
                </a>
              </div>
              <div class="flex flex-col xl:flex-row gap-x-5">
                <a
                  href="https://community.dok.ma/blog-dok-ma/"
                  target="_blank"
                  class="font-EffraR text-base py-2 xl:py-0"
                  style="color: #212121"
                  >{{ $t("patient.communaute") }}</a
                >
                <router-link
                  :to="{ name: 'simulation' }"
                  class="font-EffraR text-base py-2 xl:py-0"
                  style="color: #212121"
                  >{{ $t("patient.simulation") }}</router-link
                >
                <router-link
                  :to="{ name: 'pricingPage' }"
                  class="font-EffraR text-base py-2 xl:py-0"
                  style="color: #212121"
                  >{{ $t("patient.trafic_dok") }}</router-link
                >
                <router-link
                  :to="{ name: 'policies' }"
                  class="font-EffraR text-base py-2 xl:py-0"
                  style="color: #212121"
                >
                  {{ $t("patient.politique_confidentiale") }}
                </router-link>
                <a
                  href="https://help.dok.ma/fr/"
                  target="_blank"
                  class="font-EffraR text-base py-2 xl:py-0"
                  style="color: #212121"
                >
                  {{ $t("patient.faq") }}
                </a>
                <a
                  href="#"
                  class="font-EffraR text-base py-2 xl:py-0"
                  style="color: #212121"
                >
                  {{ $t("patient.support") }}
                </a>
                <a
                  href="#"
                  class="font-EffraR text-base py-2 xl:py-0 hidden"
                  style="color: #212121"
                >
                  {{ $t("patient.contact") }}
                </a>
                <a
                  href="#"
                  class="font-EffraR text-base py-2 xl:py-0 hidden"
                  style="color: #212121"
                >
                  {{ $t("patient.blog") }}
                </a>
                <a
                  href="#"
                  class="font-EffraR text-base py-2 xl:py-0 hidden"
                  style="color: #212121"
                >
                  {{ $t("patient.communaute") }}
                </a>
                <a
                  href="#"
                  class="font-EffraR text-base py-2 xl:py-0 hidden"
                  style="color: #212121"
                >
                  {{ $t("patient.actualites") }}
                </a>
              </div>
            </div>
          </div>
          <!---END CONTAINER-->
        </div>
      </div>

      <footer class="bg-white pt-5 pb-8 px-5 xl:px-0 hidden">
        <div class="xl:container grid gap-1 grid-cols-1 xl:grid-cols-4">
          <div
            id="propos"
            class="
              bg-grayfoot
              xl:bg-transparent
              shadow
              xl:shadow-none
              rounded-cu
              xl:rounded-none
              py-3
              px-2
              mb-5
              xl:mb-0
            "
          >
            <div class="flex justify-between items-center xl:mb-4">
              <h5 class="font-EffraR text-base text-black font-semibold flex-1">
                {{ $t("patient.about_dok") }}
              </h5>
              <i
                class="
                  fas
                  fa-angle-down
                  text-3xl
                  cursor-pointer
                  block
                  xl:hidden
                "
                @click="dropdownMobile.propos = !dropdownMobile.propos"
              ></i>
            </div>

            <div
              :class="
                dropdownMobile.propos
                  ? 'flex flex-col'
                  : 'hidden xl:flex flex-col'
              "
            >
              <a href="#" class="text-base font-EffraR text-black mb-1"
                >Qui sommes nous?</a
              >
              <a href="#" class="text-base font-EffraR text-black mb-1"
                >Consultation vidéo - Téléconsultation</a
              >
              <a href="#" class="text-base font-EffraR text-black mb-1"
                >Carrières</a
              >
              <a href="#" class="text-base font-EffraR text-black mb-1"
                >Presse</a
              >
              <a href="#" class="text-base font-EffraR text-black mb-1"
                >Besoin d'aide ?</a
              >
              <a href="#" class="text-base font-EffraR text-black"
                >Notifications frauduleuses</a
              >
            </div>
          </div>
          <div
            id="fréquentes"
            class="
              bg-grayfoot
              xl:bg-transparent
              shadow
              xl:shadow-none
              rounded-cu
              xl:rounded-none
              py-3
              px-2
              mb-5
              xl:mb-0
            "
          >
            <div class="flex justify-between items-center xl:mb-4">
              <h5 class="font-EffraR text-base text-black font-semibold flex-1">
                Recherches fréquentes
              </h5>
              <i
                class="
                  fas
                  fa-angle-down
                  text-3xl
                  cursor-pointer
                  block
                  xl:hidden
                "
                @click="dropdownMobile.frequentes = !dropdownMobile.frequentes"
              ></i>
            </div>

            <div
              :class="
                dropdownMobile.frequentes
                  ? 'flex flex-col'
                  : 'hidden xl:flex flex-col'
              "
            >
              <a
                href="#"
                @click="
                  () => {
                    this.$router.push({
                      name: 'doctorsList',
                      query: {
                        query: `chirurgien dentiste casablanca`
                      }
                    });
                  }
                "
                class="text-base font-EffraR text-black mb-1"
                >Chirurgien-dentiste Casablanca</a
              >
              <a
                href="#"
                @click="
                  () => {
                    this.$router.push({
                      name: 'doctorsList',
                      query: {
                        query: `pédiatre casablanca`
                      }
                    });
                  }
                "
                class="text-base font-EffraR text-black mb-1"
                >Pédiatre Casablanca</a
              >
              <a
                href="#"
                @click="
                  () => {
                    this.$router.push({
                      name: 'doctorsList',
                      query: {
                        query: `gynécologue médical et obstétrique marrakech`
                      }
                    });
                  }
                "
                class="text-base font-EffraR text-black mb-1"
                >Gynécologue médical et obstétrique Marrakech</a
              >
              <a
                href="#"
                @click="
                  () => {
                    this.$router.push({
                      name: 'doctorsList',
                      query: {
                        query: `ophtalmologue marrakech`
                      }
                    });
                  }
                "
                class="text-base font-EffraR text-black mb-1"
                >Ophtalmologue Marrakech</a
              >
              <a
                href="#"
                @click="
                  () => {
                    this.$router.push({
                      name: 'doctorsList',
                      query: {
                        query: `dermatologue et vénérologue rabat`
                      }
                    });
                  }
                "
                class="text-base font-EffraR text-black mb-1"
                >Dermatologue et vénérologue Rabat</a
              >
              <a
                href="#"
                @click="
                  () => {
                    this.$router.push({
                      name: 'doctorsList',
                      query: {
                        query: `ostéopathe rabat`
                      }
                    });
                  }
                "
                class="text-base font-EffraR text-black mb-1"
                >Ostéopathe Rabat</a
              >
              <a
                href="#"
                @click="
                  () => {
                    this.$router.push({
                      name: 'doctorsList',
                      query: {
                        query: `masseur kinésithérapeute tanger`
                      }
                    });
                  }
                "
                class="text-base font-EffraR text-black mb-1"
                >Masseur-kinésithérapeute Tanger</a
              >
              <a
                href="#"
                @click="
                  () => {
                    this.$router.push({
                      name: 'doctorsList',
                      query: {
                        query: `pédicure podologue tanger`
                      }
                    });
                  }
                "
                class="text-base font-EffraR text-black"
                >Pédicure-podologue Tanger</a
              >
              <a
                href="#"
                @click="
                  () => {
                    this.$router.push({
                      name: 'doctorsList',
                      query: {
                        query: `sage femme fès`
                      }
                    });
                  }
                "
                class="text-base font-EffraR text-black"
                >Sage-femme Fès</a
              >
            </div>
          </div>
          <div
            class="
              bg-grayfoot
              xl:bg-transparent
              shadow
              xl:shadow-none
              rounded-cu
              xl:rounded-none
              py-3
              px-2
              mb-5
              xl:mb-0
            "
          >
            <div class="flex justify-between items-center xl:mb-4">
              <h5 class="font-EffraR text-base text-black font-semibold flex-1">
                Trouvez votre spécialiste
              </h5>
              <i
                class="
                  fas
                  fa-angle-down
                  text-3xl
                  cursor-pointer
                  block
                  xl:hidden
                "
                @click="
                  dropdownMobile.specialiste = !dropdownMobile.specialiste
                "
              ></i>
            </div>
            <div
              class="mb-3"
              :class="
                dropdownMobile.specialiste
                  ? 'flex flex-col'
                  : 'hidden xl:flex flex-col'
              "
            >
              <a
                href="#"
                @click="
                  () => {
                    this.$router.push({
                      name: 'doctorsList',
                      query: {
                        query: `chirurgien-dentiste`
                      }
                    });
                  }
                "
                class="text-base font-EffraR text-black mb-1"
                >Chirurgien-dentiste
              </a>
              <a
                href="#"
                @click="
                  () => {
                    this.$router.push({
                      name: 'doctorsList',
                      query: {
                        query: `médecin généraliste`
                      }
                    });
                  }
                "
                class="text-base font-EffraR text-black mb-1"
                >Médecin généraliste</a
              >
              <a
                href="#"
                @click="
                  () => {
                    this.$router.push({
                      name: 'doctorsList',
                      query: {
                        query: `pédiatre`
                      }
                    });
                  }
                "
                class="text-base font-EffraR text-black mb-1"
                >Pédiatre</a
              >
              <a
                href="#"
                @click="
                  () => {
                    this.$router.push({
                      name: 'doctorsList',
                      query: {
                        query: `gynécologue médical et obstétrique`
                      }
                    });
                  }
                "
                class="text-base font-EffraR text-black mb-1"
                >Gynécologue médical et obstétrique</a
              >
              <a
                href="#"
                @click="
                  () => {
                    this.$router.push({
                      name: 'doctorsList',
                      query: {
                        query: `ophtalmologue`
                      }
                    });
                  }
                "
                class="text-base font-EffraR text-black mb-1"
                >Ophtalmologue</a
              >
              <a
                href="#"
                @click="
                  () => {
                    this.$router.push({
                      name: 'doctorsList',
                      query: {
                        query: `dermatologue et vénérologue`
                      }
                    });
                  }
                "
                class="text-base font-EffraR text-black mb-1"
                >Dermatologue et vénérologue</a
              >
              <a
                href="#"
                @click="
                  () => {
                    this.$router.push({
                      name: 'doctorsList',
                      query: {
                        query: `ostéopathe`
                      }
                    });
                  }
                "
                class="text-base font-EffraR text-black mb-1"
                >Ostéopathe</a
              >
              <a
                href="#"
                @click="
                  () => {
                    this.$router.push({
                      name: 'doctorsList',
                      query: {
                        query: `pédicure-podologue casa`
                      }
                    });
                  }
                "
                class="text-base font-EffraR text-black"
                >Pédicure-podologue Casa</a
              >
              <a
                href="#"
                @click="
                  () => {
                    this.$router.push({
                      name: 'doctorsList',
                      query: {
                        query: `masseur-kinésithérapeute`
                      }
                    });
                  }
                "
                class="text-base font-EffraR text-black"
                >Masseur-kinésithérapeute</a
              >
              <a
                href="#"
                @click="
                  () => {
                    this.$router.push({
                      name: 'doctorsList',
                      query: {
                        query: `pédicure-podologue`
                      }
                    });
                  }
                "
                class="text-base font-EffraR text-black"
                >Pédicure-podologue</a
              >
              <a
                href="#"
                @click="
                  () => {
                    this.$router.push({
                      name: 'doctorsList',
                      query: {
                        query: `sage-femme`
                      }
                    });
                  }
                "
                class="text-base font-EffraR text-black"
                >Sage-femme</a
              >
            </div>
            <div
              :class="
                dropdownMobile.specialiste
                  ? 'flex flex-col mt-3'
                  : 'hidden xl:flex flex-col'
              "
            >
              <a
                href="#"
                @click="
                  () => {
                    this.$router.push({
                      name: 'doctorsList'
                    });
                  }
                "
                class="text-base font-EffraR text-black mb-1"
                >Toutes les spécialités
              </a>
              <a
                href="#"
                @click="
                  () => {
                    this.$router.push({
                      name: 'doctorsList'
                    });
                  }
                "
                class="text-base font-EffraR text-black mb-1"
                >Toutes les expertises</a
              >
            </div>
            <div
              class="flex-col"
              :class="dropdownMobile.specialiste ? 'flex' : 'hidden'"
            >
              <a
                href="#"
                @click="
                  () => {
                    this.$router.push({
                      name: 'doctorsList',
                      query: {
                        query: `orl`
                      }
                    });
                  }
                "
                class="text-base font-EffraR text-black mb-1"
              >
                ORL</a
              >

              <a
                href="#"
                @click="
                  () => {
                    this.$router.push({
                      name: 'doctorsList',
                      query: {
                        query: `allergologue`
                      }
                    });
                  }
                "
                class="text-base font-EffraR text-black mb-1"
              >
                Allergologue</a
              >

              <a
                href="#"
                @click="
                  () => {
                    this.$router.push({
                      name: 'doctorsList',
                      query: {
                        query: `chirurgien urologue`
                      }
                    });
                  }
                "
                class="text-base font-EffraR text-black mb-1"
              >
                Chirurgien urologue</a
              >

              <a
                href="#"
                @click="
                  () => {
                    this.$router.push({
                      name: 'doctorsList',
                      query: {
                        query: `rhumatologue`
                      }
                    });
                  }
                "
                class="text-base font-EffraR text-black mb-1"
              >
                Rhumatologue</a
              >

              <a
                href="#"
                @click="
                  () => {
                    this.$router.push({
                      name: 'doctorsList',
                      query: {
                        query: `stomatologue`
                      }
                    });
                  }
                "
                class="text-base font-EffraR text-black mb-1"
              >
                Stomatologue</a
              >

              <a
                href="#"
                @click="
                  () => {
                    this.$router.push({
                      name: 'doctorsList',
                      query: {
                        query: `endocrinologue`
                      }
                    });
                  }
                "
                class="text-base font-EffraR text-black mb-1"
              >
                Endocrinologue</a
              >

              <a
                href="#"
                @click="
                  () => {
                    this.$router.push({
                      name: 'doctorsList',
                      query: {
                        query: `chirurgien orthopédiste`
                      }
                    });
                  }
                "
                class="text-base font-EffraR text-black mb-1"
              >
                Chirurgien orthopédiste</a
              >

              <a
                href="#"
                @click="
                  () => {
                    this.$router.push({
                      name: 'doctorsList',
                      query: {
                        query: `diététicien`
                      }
                    });
                  }
                "
                class="text-base font-EffraR text-black mb-1"
              >
                Diététicien</a
              >

              <a
                href="#"
                @click="
                  () => {
                    this.$router.push({
                      name: 'doctorsList',
                      query: {
                        query: `psychologue`
                      }
                    });
                  }
                "
                class="text-base font-EffraR text-black mb-1"
              >
                Psychologue</a
              >
              <a
                href="#"
                @click="
                  () => {
                    this.$router.push({
                      name: 'doctorsList',
                      query: {
                        query: `neurologue`
                      }
                    });
                  }
                "
                class="text-base font-EffraR text-black mb-1"
              >
                Neurologue</a
              >
            </div>
          </div>
          <div class="hidden xl:block">
            <div class="flex flex-col">
              <a
                href="#"
                @click="
                  () => {
                    this.$router.push({
                      name: 'doctorsList',
                      query: {
                        query: `orl`
                      }
                    });
                  }
                "
                class="text-base font-EffraR text-black mb-1"
              >
                ORL</a
              >

              <a
                href="#"
                @click="
                  () => {
                    this.$router.push({
                      name: 'doctorsList',
                      query: {
                        query: `allergologue`
                      }
                    });
                  }
                "
                class="text-base font-EffraR text-black mb-1"
              >
                Allergologue</a
              >

              <a
                href="#"
                @click="
                  () => {
                    this.$router.push({
                      name: 'doctorsList',
                      query: {
                        query: `chirurgien urologue`
                      }
                    });
                  }
                "
                class="text-base font-EffraR text-black mb-1"
              >
                Chirurgien urologue</a
              >

              <a
                href="#"
                @click="
                  () => {
                    this.$router.push({
                      name: 'doctorsList',
                      query: {
                        query: `rhumatologue`
                      }
                    });
                  }
                "
                class="text-base font-EffraR text-black mb-1"
              >
                Rhumatologue</a
              >

              <a
                href="#"
                @click="
                  () => {
                    this.$router.push({
                      name: 'doctorsList',
                      query: {
                        query: `stomatologue`
                      }
                    });
                  }
                "
                class="text-base font-EffraR text-black mb-1"
              >
                Stomatologue</a
              >

              <a
                href="#"
                @click="
                  () => {
                    this.$router.push({
                      name: 'doctorsList',
                      query: {
                        query: `endocrinologue`
                      }
                    });
                  }
                "
                class="text-base font-EffraR text-black mb-1"
              >
                Endocrinologue</a
              >

              <a
                href="#"
                @click="
                  () => {
                    this.$router.push({
                      name: 'doctorsList',
                      query: {
                        query: `chirurgien orthopédiste`
                      }
                    });
                  }
                "
                class="text-base font-EffraR text-black mb-1"
              >
                Chirurgien orthopédiste</a
              >

              <a
                href="#"
                @click="
                  () => {
                    this.$router.push({
                      name: 'doctorsList',
                      query: {
                        query: `diététicien`
                      }
                    });
                  }
                "
                class="text-base font-EffraR text-black mb-1"
              >
                Diététicien</a
              >

              <a
                href="#"
                @click="
                  () => {
                    this.$router.push({
                      name: 'doctorsList',
                      query: {
                        query: `psychologue`
                      }
                    });
                  }
                "
                class="text-base font-EffraR text-black mb-1"
              >
                Psychologue</a
              >
              <a
                href="#"
                @click="
                  () => {
                    this.$router.push({
                      name: 'doctorsList',
                      query: {
                        query: `neurologue`
                      }
                    });
                  }
                "
                class="text-base font-EffraR text-black mb-1"
              >
                Neurologue</a
              >
            </div>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    downLoadApp: {
      type: Boolean,
      required: false
    },
    reverseFooter: {
      type: Boolean,
      required: false
    },
    hiddenContent: {
      type: Boolean,
      required: false
    },
    customClassLink: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      inputPhoneNumber: {
        value: null,
        error: false
      },
      dropdownMobile: {
        propos: false,
        frequentes: false,
        specialiste: false
      }
    };
  },
  methods: {
    onSentSMS() {
      if (!this.inputPhoneNumber.value) {
        return (this.inputPhoneNumber.error = true); // Validation Input Phone
      }

      this.inputPhoneNumber.error = false; // Validation Input Phone

      // Sent Request To the api
      this.$vs.loading(); // Start Loading
      this.$store.dispatch("user/SENT_SMS_DOWNLOAD_APP", {
        mobile: this.inputPhoneNumber.value,
        onData: ({ ok, message }) => {
          this.$vs.notify({
            time: 4000,
            text: message,
            color: ok ? "success" : "danger",
            iconPack: "feather",
            icon: !ok ? "icon-alert-triangle" : "icon-check-circle"
          });

          this.$vs.loading.close(); // Close Loading
        }
      });
    }
  }
};
</script>

<style></style>
